<template>
    <section>
        <modal ref="modalDenegarCambios" titulo="Denegar Cambios" icon="close" @guardar="updateDatos">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 px-5 justify-center mb-4">
                    <div class="col-10 text-general text-center">
                        Escribe el mensaje que desea enviarle al {{ this.$config.vendedor + '.' }} 
                    </div>
                </div>
                <ValidationProvider v-slot="{errors}" rules="required|max:500" name="justificación">
                    <div class="row mx-0 px-5 justify-center">
                        <div class="col">
                            <el-input v-model="model.justificacion" type="textarea" :rows="5" maxlength="500" show-word-limit placeholder="Digité la justificación" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </div>
                    </div>
                </ValidationProvider>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import CambioDatos from "../../../../services/tiendas/cambio_datos";

export default {
    data(){
        return{
            model: {
                id:null,
                id_tienda:null,
                justificacion: '',
                tipo: 2
            }
        }
    },
    methods: {
        toggle(model){
            this.model.id = model.id
            this.model.id_tienda = model.id_tienda
            this.$refs.modalDenegarCambios.toggle();
        },
        async updateDatos(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const {data} = await CambioDatos.updateDatos(this.model)
                this.notificacion('Mensaje', 'Cambios denegados', 'success')
                this.$emit('actualizar')
                this.$refs.modalDenegarCambios.toggle()
                this.model.id = null
                this.model.id_tienda = null
                this.model.justificacion = ''
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
